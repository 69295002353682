import React, { useEffect } from 'react';
import { graphql, StaticQuery } from 'gatsby';
// eslint-disable-next-line import/no-extraneous-dependencies
import { globalHistory as location } from '@reach/router';

import './styles.scss';

const CONTACT_MODAL_QUERY = graphql`
  query contactModalQuery {
    allDatoCmsState {
      edges {
        node {
          id
          stateTitle
          stateSlug
        }
      }
    }
  }
`;

const ContactModalForm = () => {
  const path = location.location.pathname;

  const getFormId = () => {
    if (path.includes('iowa-agents')) {
      return '33642169-3eb8-4727-aac5-45d71c19ff95';
    }
    if (path.includes('minnesota-agents')) {
      return '8b2816dd-97fe-45fd-a41d-3b22492bc206';
    }
    if (path.includes('missouri-agents')) {
      return 'cc083f08-1ce9-4f7c-8819-1808ac0dfcc7';
    }
    if (path.includes('kentucky-agents')) {
      return '89c54d37-2bd1-48d2-89d8-dae4191ba4c5';
    }
    if (path.includes('maryland-agents')) {
      return '47240907-7c76-4ce2-b5b4-2f974d8e743e';
    }
    if (path.includes('ohio-agents')) {
      return '06485207-7c60-48f9-84e5-002a10cf4745';
    }
    if (path.includes('tennessee-agents')) {
      return 'a9f06733-03e0-49e9-8840-119c5a0e8aa6';
    }
    if (path.includes('new-jersey-agents')) {
      return 'ee7e2edd-a022-41cd-abbf-637f7ae26f4f';
    }
    if (path.includes('nebraska-agents')) {
      return '7f2af826-38fc-4444-826a-560fb5f8d9b8';
    }
    if (path.includes('louisiana-agents')) {
      return '212cef00-34b3-4913-8c7d-71e6fca153d5';
    }
    if (path.includes('virginia-agents')) {
      return 'b763d834-cba6-4007-a361-5c90a4b45f1c';
    }
    if (path.includes('florida-agents')) {
      return '45b02808-c5be-41ce-86eb-e28e7acd2ce1';
    }
    if (path.includes('alabama-agents')) {
      return 'fecf65fb-3478-4145-807b-10864c169719';
    }
    if (path.includes('mississippi-agents')) {
      return '3f428f3c-36d5-464a-ac1c-6b39d97c0f04';
    }
    if (path.includes('oklahoma-agents')) {
      return 'd68d8c79-5e33-418f-99df-ce8c1991cc69';
    }
    if (path.includes('texas-agents')) {
      return '3be661e3-b2fc-4554-9f1e-ac4ab61cbb62';
    }
    return '64752ae8-67df-45e4-ba48-ed65358a1eed';
  };

  useEffect(() => {
    if (window.hbspt) {
      window.hbspt.forms.create({
        portalId: '7013510',
        formId: getFormId(),
        target: '#hubspotForm',
      });
    }

    const script = document.createElement('script');
    script.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'getInTouchForm'
      });
    `;
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <StaticQuery
      query={CONTACT_MODAL_QUERY}
      render={() => (
        <section className="contact-us-form-modal">
          <div className="wrapper" id="hubspotForm" />
        </section>
      )}
    />
  );
};

export default ContactModalForm;
