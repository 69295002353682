import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import { get } from 'lodash';

import FavIcon from '../../assets/images/icons/favicon.ico';

// Query
const SITE_QUERY = graphql`
  query SiteMeta {
    site {
      siteMetadata {
        baseUrl
      }
    }
    datoCmsSite {
      globalSeo {
        twitterAccount
        titleSuffix
        siteName
        facebookPageUrl
        fallbackSeo {
          twitterCard
          title
          description
        }
      }
    }
  }
`;

const Head = ({ title, seoProps }) => (
  <StaticQuery
    query={SITE_QUERY}
    render={(data) => {
      const globalSeo = get(data, 'datoCmsSite.globalSeo');

      // Components Props
      const ComponentProps = {
        baseUrl: get(data, 'site.siteMetadata.baseUrl'),
        // Global Seo config
        twitterAccount: get(globalSeo, 'twitterAccount'),
        facebookPageUrl: get(globalSeo, 'facebookPageUrl'),
        // Pased based seo config
        title: title || get(globalSeo, 'fallbackSeo.title'),
        description:
          get(seoProps, 'description') ||
          get(globalSeo, 'fallbackSeo.decription'),
        cardImage:
          get(seoProps, 'cardImage') || get(globalSeo, 'fallbackSeo.image.url'),
        twitterCardType:
          get(seoProps, 'twitterCard') ||
          get(globalSeo, 'fallbackSeo.twitterCard'),
      };
      return (
        <Helmet
          link={[
            {
              rel: 'shortcut icon',
              href: FavIcon,
            },
          ]}
        >
          {/* Site meta */}
          <html lang="en" />
          <title>{ComponentProps.title}</title>
          <meta charset="utf-8" />
          <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
          <meta property="type" content="website" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
          <meta property="title" content={ComponentProps.title} />
          <meta name="description" content={ComponentProps.description} />

          {/* Facebook card */}
          <meta name="fb:app_id" content={ComponentProps.facebookPageUrl} />
          <meta property="og:title" content={ComponentProps.title} />
          <meta
            property="og:description"
            content={ComponentProps.description}
          />
          <meta property="og:image" content={ComponentProps.cardImage} />
          <meta property="og:url" content={ComponentProps.baseUrl} />

          {/* Twitter card */}
          <meta name="twitter:card" content={ComponentProps.image} />
          <meta
            property="twitter:site"
            content={ComponentProps.twitterAccount}
          />
          <meta name="twitter:title" content={ComponentProps.title} />
          <meta
            name="twitter:description"
            content={ComponentProps.description}
          />
          <meta name="twitter:image" content={ComponentProps.cardImage} />
        </Helmet>
      );
    }}
  />
);

// Proptypes
Head.propTypes = {
  title: PropTypes.string,
  seoProps: PropTypes.shape({
    twitterCard: PropTypes.string,
    cardImage: PropTypes.string,
    description: PropTypes.string,
  }),
};

// Default props
Head.defaultProps = {
  title: 'Bolt Access',
  seoProps: {},
};

export default Head;
