import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Head from '../head';
import Header from '../header';
import Footer from '../footer';
import Loader from '../loader';
import ContactContext from '../../context/ContactContext';
import ContactModal from '../modal/contact-us-modal';

const Layout = ({ children, title, seoProps }) => {
  // To avoid error during server side rendering
  useEffect(() => {
    if (window !== 'undefined') {
      // eslint-disable-next-line global-require
      require('smooth-scroll')('a[href*="#"]');
    }
  }, []);

  return (
    <ContactContext.Consumer>
      {({ modal, toggleModal }) => (
        <div>
          <Head title={title} seoProps={seoProps} />
          <Loader />
          <Header />
          <main>{children}</main>
          <Footer />
          {modal ? <ContactModal handleClose={toggleModal} /> : null}
        </div>
      )}
    </ContactContext.Consumer>
  );
};

Layout.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(PropTypes.object).isRequired,
  seoProps: PropTypes.shape({
    twitterCard: PropTypes.string,
    cardImage: PropTypes.string,
    description: PropTypes.string,
  }),
};

Layout.defaultProps = {
  seoProps: {},
};

export default Layout;
