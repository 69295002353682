import React from 'react';
import PropTypes from 'prop-types';

import ContactModalForm from '../../forms/contact-form-modal';

import './styles.scss';

const ContactModal = ({ handleClose }) => (
  <section className="contact-modal-area">
    <div className="contact-modal">
      <div className="left-section" onKeyPress={null} onClick={handleClose} />
      <div className="right-section">
        <button type="button" className="close" onClick={handleClose}>
          Close
        </button>
        <h2>Get in Touch</h2>
        <ContactModalForm />
      </div>
    </div>
  </section>
);

ContactModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default ContactModal;
