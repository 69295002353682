/* eslint-disable react/button-has-type */
/* eslint-disable no-nested-ternary */
/* eslint-disable object-curly-newline */
import React from 'react';
import PropTypes from 'prop-types';
import { Link, navigate } from 'gatsby';

import RightArrowIcon from '../../../assets/svgs/icon-chevron-right.svg';
import './styles.scss';

const PrimaryButton = (props) => {
  const {
    text,
    link,
    color,
    icon,
    onClick,
    style,
    ref: animateRef,
    type,
  } = props;

  return (
    <div className="button-container" ref={animateRef} style={style}>
      <button
        // eslint-disable-next-line react/button-has-type
        type={type}
        onClick={() => {
          if (link) {
            navigate(link);
          } else if (onClick) {
            onClick();
          }
        }}
        className={`default-button ${color}`}
        title={text}
      >
        <span>{text}</span>
        {icon ? <RightArrowIcon /> : ''}
      </button>
    </div>
  );
};

const SecondaryButton = ({
  text,
  link,
  icon,
  onClick,
  style,
  isBtn,
  ref: animateRef,
}) => (
  <div className="button-container" ref={animateRef} style={style}>
    {text && !isBtn ? (
      <Link
        className="secondary-button"
        to={link}
        title={text}
        onClick={(e) => {
          if (onClick) {
            e.preventDefault();
            onClick();
          }
        }}
      >
        {text}
        {icon ? <RightArrowIcon /> : ''}
      </Link>
    ) : text && isBtn ? (
      <button
        className="secondary-button"
        onClick={(e) => {
          if (onClick) {
            e.preventDefault();
            onClick();
          }
        }}
      >
        {text}
        {icon ? <RightArrowIcon /> : ''}
      </button>
    ) : null}
  </div>
);

// Proptypes
PrimaryButton.propTypes = {
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  color: PropTypes.string,
  icon: PropTypes.bool,
  onClick: PropTypes.func,
  style: PropTypes.shape({
    transition: PropTypes.string,
    opacity: PropTypes.number,
    transform: PropTypes.string,
  }),
  ref: PropTypes.objectOf(PropTypes.object),
  type: PropTypes.string,
};

PrimaryButton.defaultProps = {
  onClick: false,
  icon: false,
  type: 'button',
  ref: {},
  style: {},
  color: '',
};

SecondaryButton.propTypes = {
  text: PropTypes.arrayOf(PropTypes.object).isRequired,
  link: PropTypes.arrayOf(PropTypes.object).isRequired,
  icon: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClick: PropTypes.func,
  style: PropTypes.shape({
    transition: PropTypes.string,
    opacity: PropTypes.number,
    transform: PropTypes.string,
  }).isRequired,
  ref: PropTypes.objectOf(PropTypes.object).isRequired,
  isBtn: PropTypes.bool,
};

SecondaryButton.defaultProps = {
  onClick: false,
  isBtn: false,
};

export { PrimaryButton, SecondaryButton };
