import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import parse from 'html-react-parser';
import { get } from 'lodash';

import './styles.scss';

const FOOTER_QUERY = graphql`
  query footerQuery {
    allDatoCmsFooterNavigationGroup(sort: { fields: menuOrder, order: DESC }) {
      edges {
        node {
          menuOrder
          id
          navigationGroupLabel
          navigationGroupLinks {
            id
            itemTitle
            itemUrl
          }
        }
      }
    }
    allDatoCmsFooterMetaInformation {
      edges {
        node {
          boltAccessLogo {
            alt
            url
            gatsbyImageData(placeholder: BLURRED)
          }
          taglineTitle
          taglineDescription
          socialSectionTitle
          copyright
          socialMedia {
            socialIcon {
              url
            }
            id
            socialLink
          }
        }
      }
    }
  }
`;

const Footer = () => (
  <StaticQuery
    query={FOOTER_QUERY}
    render={(data) => {
      const metaInfo = get(
        data,
        'allDatoCmsFooterMetaInformation.edges[0].node'
      );
      const navGroup = get(data, 'allDatoCmsFooterNavigationGroup.edges');

      return (
        <footer>
          <div className="footer-links">
            <div className="wrapper">
              <div className="footer-links-flex-container">
                {Array.isArray(navGroup)
                  ? navGroup.map((item) => (
                      <div className="footer-links-flexbox" key={item.node.id}>
                        <div className="footer-links-flex-content">
                          <h1>{item.node.navigationGroupLabel}</h1>
                          <ul>
                            {Array.isArray(item.node.navigationGroupLinks)
                              ? item.node.navigationGroupLinks.map((link) => (
                                  <li key={link.id}>
                                    <Link to={link.itemUrl || '#'}>
                                      {link.itemTitle}
                                    </Link>
                                  </li>
                                ))
                              : null}
                          </ul>
                        </div>
                      </div>
                    ))
                  : null}
              </div>
              <div className="footer-logo">
                <Link to="/">
                  {metaInfo.boltAccessLogo ? (
                    <img
                      src={metaInfo.boltAccessLogo.url}
                      alt="boltaccess logo"
                    />
                  ) : null}
                </Link>
              </div>
            </div>
          </div>
          <div className="connect-with-us">
            <div className="wrapper">
              <div
                className={
                  metaInfo.taglineTitle || metaInfo.taglineDescription
                    ? 'connect-flex-container two-column'
                    : 'connect-flex-container'
                }
              >
                {metaInfo.taglineTitle || metaInfo.taglineDescription ? (
                  <div className="connect-flexbox">
                    <div className="connect-flex-content">
                      <p>{metaInfo.taglineTitle}</p>
                      <h4>{metaInfo.taglineDescription}</h4>
                    </div>
                  </div>
                ) : null}
                <div className="connect-flexbox">
                  <div className="connect-flex-content">
                    <p>{metaInfo.socialSectionTitle}</p>
                    <ul>
                      {Array.isArray(metaInfo.socialMedia)
                        ? metaInfo.socialMedia.map((social) => (
                            <li key={social.id}>
                              <a
                                href={social.socialLink}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {social.socialIcon ? (
                                  <img
                                    src={social.socialIcon.url}
                                    alt="Social Links"
                                  />
                                ) : null}
                              </a>
                            </li>
                          ))
                        : null}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-copyright">
            <div className="wrapper">
              <p>{parse(metaInfo.copyright)}</p>
            </div>
          </div>
        </footer>
      );
    }}
  />
);

export default Footer;
