import React, { useEffect } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';

import './styles.scss';

const CONTACT_FORM_QUERY = graphql`
  query contactUsQuery1 {
    allDatoCmsState {
      edges {
        node {
          id
          stateTitle
          stateSlug
        }
      }
    }
  }
`;

const Form = (props) => {
  useEffect(() => {
    let a;
    const showForm = () => {
      window.hbspt.forms.create({
        region: 'na1',
        portalId: props.portalId,
        formId: props.formId,
        target: `#${props.targetId}`,
      });
    };
    const check = () => {
      if (window.hbspt) {
        clearInterval(a);
        showForm();
      }
    };
    if (window.hbspt) {
      showForm();
    } else {
      a = setInterval(() => check(), 500);
    }
  }, []);
  return (
    <StaticQuery
      query={CONTACT_FORM_QUERY}
      render={() => (
        <section className="contact-us-form">
          <div className="wrapper" id={props.targetId} />
        </section>
      )}
    />
  );
};

// Proptypes
Form.propTypes = {
  portalId: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  targetId: PropTypes.string.isRequired,
};

export default Form;
