import React from 'react';
import PropTypes from 'prop-types';

import LoginModalForm from '../../forms/login-modal';

import './styles.scss';

const LoginModal = ({ handleClose }) => (
  <section className="login-modal-area">
    <div className="login-modal">
      <div className="left-section" onKeyPress={null} onClick={handleClose} />
      <div className="right-section">
        <button type="button" className="close" onClick={handleClose}>
          Close
        </button>
        <h2>Login</h2>
        <span className="mandatory">All fields are mandatory.</span>
        <LoginModalForm />
      </div>
    </div>
  </section>
);

LoginModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default LoginModal;
