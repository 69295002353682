import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';

import RightArrowIcon from '../../../assets/svgs/icon-chevron-right.svg';
import { PrimaryButton } from '../../elements/buttons';
import RouteUtils from '../../../utils/route-utils';

// Styles
import './styles.scss';

const LoginModalForm = () => {
  //  State
  const [username, setUserName] = useState({
    value: '',
    error: '',
  });
  const [password, setPassword] = useState({
    value: '',
    error: '',
  });

  const [error, handleError] = useState('');

  const setError = () => {
    const ErrorMessage = RouteUtils.getParameterByName('Error');
    if (ErrorMessage) {
      handleError(ErrorMessage);
    }
  };

  useEffect(() => {
    setError();
    const script = document.createElement('script');
    script.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'loginForm'
      });
    `;
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  // Forgot password route
  const FORGOT_PASSWORD =
    'https://signup.superioraccess.com/Agentregistration/ForgotPassword.aspx';

  // Handle Validation
  const handleValidation = (value, label) => {
    if (!value && label === 'username') {
      setUserName({
        ...username,
        error: 'Username is required.',
        value,
      });
    } else if (!value && label === 'password') {
      setPassword({
        ...password,
        error: 'Password is required.',
        value,
      });
    }
  };

  // Handle input change
  const handleInputChange = (value = '', label) => {
    const trimmedValue = value.trim();
    if (label === 'username') {
      setUserName({
        ...username,
        error: '',
        value: trimmedValue,
      });
    } else if (label === 'password') {
      setPassword({
        ...password,
        error: '',
        value: trimmedValue,
      });
    }
    handleValidation(trimmedValue, label);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleValidation(username.value, 'username');
    handleValidation(password.value, 'password');

    if (username.value && password.value) {
      e.target.submit();
    }
  };

  return (
    <section className="login-form-modal">
      <div className="wrapper">
        <form
          action="https://adb4.superioraccess.com/WebsiteLogin.aspx"
          autoComplete="off"
          method="post"
          onSubmit={handleSubmit}
        >
          <div className="form-input">
            <TextField
              id="username"
              label="Username"
              value={username.value}
              onChange={(e) => {
                handleInputChange(e.target.value, 'username');
              }}
              fullWidth
              style={{ padding: '22px 0' }}
              type="text"
              name="txtId_subMaster"
              error={!!username.error || !!error}
              helperText={username.error}
            />
          </div>
          <div className="form-input">
            <TextField
              id="password"
              label="Password"
              value={password.value}
              name="txtPassword_subMaster"
              onChange={(e) => {
                handleInputChange(e.target.value, 'password');
              }}
              fullWidth
              style={{ padding: '22px 0' }}
              type="password"
              error={!!password.error || !!error}
              helperText={password.error}
            />
          </div>
          <span className="error-message">{error}</span>
          <PrimaryButton
            type="submit"
            text="Log in"
            color="primary-cyan"
            icon
          />
          <div className="forgot-password">
            <a
              href={FORGOT_PASSWORD}
              title="forgot password"
              target="_blank"
              rel="noreferrer"
            >
              <span>Forgot password</span>
              <RightArrowIcon />
            </a>
          </div>
        </form>
      </div>
    </section>
  );
};

export default LoginModalForm;
