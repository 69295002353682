/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useInView } from 'react-intersection-observer';

const AnimateIn = (Component) => ({
  threshold = 0,
  triggerOnce = true,
  distance = 100,
  ...remainingProps
}) => {
  // Handle animation trigger if component is inView
  const [ref, inView] = useInView({ threshold, triggerOnce });

  return (
    <Component
      innerRef={ref}
      style={{
        transition: 'opacity 300ms, transform 500ms',
        opacity: inView ? 1 : 0,
        transform: `translateY(${inView ? 0 : distance}px)`,
      }}
      {...remainingProps}
    />
  );
};

export default AnimateIn;
