/* eslint-disable react/button-has-type */
import React, { useState, useEffect } from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import { getCookie } from 'tiny-cookie';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useLocation } from '@reach/router';

import LoginModal from '../modal/login-modal';
import RouteUtils from '../../utils/route-utils';

import BoltLogo from '../../assets/images/logos/bolt-logo-cyan-access.jpg';
import BoltLogoAlt from '../../assets/images/logos/bolt-logo-white-access.png';
import DownArrowIcon from '../../assets/svgs/icon-chevron-down.svg';
import './styles.scss';

const HEADER_QUERY = graphql`
  query headerQuery {
    allDatoCmsHeader(sort: { fields: menuOrder, order: DESC }) {
      edges {
        node {
          id
          menuItemLink
          menuItemTitle
          hasSubMenu
          menuOrder
          subMenu {
            id
            subMenuLink
            subMenuTitle
            subMenuDescription
            subMenuIcon {
              alt
              url
            }
          }
        }
      }
    }
  }
`;

/*
  menuToggle: Function to Toggle Menu
*/
const menuToggle = () => {
  const header = document.querySelector('header');
  const siteLogo = document.querySelector('header figure img');
  const hamburger = document.querySelector('header .hamburger-menu');
  if (hamburger.classList.contains('active')) {
    hamburger.classList.remove('active');
    header.classList.remove('mobile-nav');
    siteLogo.src = BoltLogo;
  } else {
    hamburger.classList.add('active');
    header.classList.add('mobile-nav');
    siteLogo.src = BoltLogoAlt;
  }
};

/*
  onMenuClick: Click Event for Menu
*/
const onMenuClick = () => {
  menuToggle();
};

/*
  onMenuKeyPress: Keypress Event for Menu
*/
const onMenuKeyPress = (e) => {
  if (e.keyCode === 13) {
    menuToggle();
  }
};

const Header = () => {
  const [modal, setModal] = useState(false);
  const [cookie, setCookie] = useState('');
  const location = useLocation();

  const handleState = () => {
    const bodyArea = document.getElementsByTagName('body')[0];
    if (!modal) {
      setModal(true);
      bodyArea.style.overflowY = 'hidden';
    } else {
      setModal(false);
      bodyArea.style.overflowY = 'auto';
    }
  };

  const checkIfErrorExists = () => {
    const ErrorMessage = RouteUtils.getParameterByName('Error');
    if (ErrorMessage) {
      setModal(true);
    }
  };

  useEffect(() => {
    checkIfErrorExists();
    setCookie(getCookie('cjevent'));
  }, []);

  return (
    <StaticQuery
      query={HEADER_QUERY}
      render={(data) => (
        <div>
          <header>
            <div className="wrapper">
              {/* Bolt Access Logo */}
              <figure>
                <Link to="/">
                  <img
                    src="https://www.datocms-assets.com/39813/1623945026-bolt-logo-cyan-access.jpg"
                    alt="boltaccess logo"
                  />
                </Link>
              </figure>
              {/* Navigation Bar */}
              <nav>
                <ul>
                  {Array.isArray(data.allDatoCmsHeader.edges)
                    ? data.allDatoCmsHeader.edges.map((item) => (
                        <li key={item.node.id}>
                          {item.node.hasSubMenu ? (
                            <div className="dropdown-menu">
                              <button type="button">
                                {item.node.menuItemTitle}
                              </button>
                              <div className="dropdown-data">
                                <div className="wrapper">
                                  <div className="dropdown-flex-container">
                                    {Array.isArray(item.node.subMenu)
                                      ? item.node.subMenu.map((menuItem) => (
                                          <div
                                            className="dropdown-flexbox"
                                            key={menuItem.id}
                                          >
                                            <div className="dropdown-flex-content">
                                              <Link
                                                to={menuItem.subMenuLink}
                                                activeClassName="active"
                                                partiallyActive="true"
                                              >
                                                {menuItem.subMenuIcon ? (
                                                  <img
                                                    src={
                                                      menuItem.subMenuIcon.url
                                                    }
                                                    alt={
                                                      menuItem.subMenuIcon.alt
                                                    }
                                                  />
                                                ) : null}
                                                <p className="submenu-title">
                                                  {menuItem.subMenuTitle}
                                                </p>
                                                <p className="submenu-text">
                                                  {menuItem.subMenuDescription}
                                                </p>
                                              </Link>
                                            </div>
                                          </div>
                                        ))
                                      : null}
                                  </div>
                                </div>
                              </div>
                              <span>
                                <DownArrowIcon />
                              </span>
                            </div>
                          ) : (
                            <Link
                              to={item.node.menuItemLink}
                              activeClassName="active"
                              partiallyActive="true"
                            >
                              {item.node.menuItemTitle}
                            </Link>
                          )}
                        </li>
                      ))
                    : null}
                </ul>
              </nav>
              {/* CTA Buttons */}
              <ul className="cta-buttons">
                {location &&
                (location?.pathname.includes('/carriers/california') ||
                  location?.pathname.includes('/carriers/texas') ||
                  location?.pathname.includes('/carriers/florida') ||
                  location?.pathname.includes('/carriers/louisiana')) ? null : (
                  <li>
                    <a
                      href={`https://signup.boltinsurance.com/welcome${
                        cookie ? `?cjevent=${cookie}` : ''
                      }`}
                      title="Sign up"
                    >
                      Sign up
                    </a>
                  </li>
                )}
                <li>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      handleState();
                    }}
                  >
                    Log in
                  </button>
                </li>
              </ul>
              {/* Hamburger */}
              <div
                className="hamburger-menu"
                onClick={onMenuClick}
                onKeyDown={onMenuKeyPress}
                role="button"
                tabIndex={0}
              >
                <span />
                <span />
                <span />
              </div>
              {/* Legal Info Section */}
              <div className="legal-info">
                <p>Legal information</p>
                <ul>
                  <li>
                    <Link to="/legal?tabIndex=0">Disclaimer</Link>
                  </li>
                  <li>
                    <Link to="/legal?tabIndex=1">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/legal?tabIndex=2">Cookie Policy</Link>
                  </li>
                </ul>
              </div>
            </div>
          </header>
          {/* Login Modal */}
          {modal ? <LoginModal handleClose={handleState} /> : null}
        </div>
      )}
    />
  );
};

export default Header;
