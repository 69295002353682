import React from 'react';
import './style.scss';

const Loader = () => (
  <div className="loader" id="loader">
    <div className="logo-slide">
      <img src="https://www.datocms-assets.com/39813/1623945026-bolt-logo-cyan-access.jpg" alt="Bolt Access Logo" />
    </div>
  </div>
);

export default Loader;
