const PROTO = {
  /**
   * Redirect on a new tab
   *
   */
  openNewTab: async (url) => {
    if (!window) return;
    // Open link in a new tab
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  },

  getParameterByName: (name, url = window.location.href) => {
    try {
      const modifyName = name.replace(/[[\]]/g, '\\$&');
      const regex = new RegExp(`[?&]${modifyName}(=([^&#]*)|&|#|$)`);
      const results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    } catch {
      // eslint-disable-next-line no-console
      // console.error('Incorrect URI');
    }
  },
};

const RouteUtils = Object.create(PROTO);

export default RouteUtils;
